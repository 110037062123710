import './App.css';
import HomeLayout from './Component/HomeLayout';

function App() {
  return (
    <HomeLayout/>
  );
}

export default App;

import React from 'react';
import { Box, Button, Typography } from '@mui/material';

const TagsSelector = ({ approachContent, title, tags, selectedTag, onTagClick }) => {
  return (
    <Box sx={{ textAlign: 'left', mb:2,}}>
<Typography variant="h6" gutterBottom sx={{fontSize:'1.125rem',fontWeight:'600'}}>
Approach
      </Typography>
     <Typography variant="h6" gutterBottom sx={{ fontSize: '1rem', mb: 2 }}> {approachContent}
 
</Typography>


      {/* Title */}
      <Typography variant="h6" gutterBottom sx={{fontSize:'1.125rem',fontWeight:'600'}}>
        {title}
      </Typography>

      {/* Tag Buttons */}
      <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
        {tags.map((tag) => (
          <Button
            key={tag}           
           disabled
            variant={ 'outlined'}
            sx={{
              borderRadius: '50px', // Rounded shape
              textTransform: 'none', // No uppercase
              fontWeight: '500',
              cursor: 'default',
              px: 2, // Padding X
              py: 0.5, // Padding Y
              color:  '#000 !important', // Text color based on selection
              backgroundColor: 'transparent !important', // Background color for selected
              borderColor: '#000 !important', // Outline color   
              fontSize:'0.875rem !important',        
            }}
          >
            {tag}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default TagsSelector;
